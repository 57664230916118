import $ from "jquery";
import { sleep } from "@/utils/utils.js";

export function switchPage(options) {
    const type = options.type;
    switch (type) {
        case "leftOut":
            translateLeftOut(options);
            break;
        case "rightIn":
            translateRightIn(options);
            break;

        default:
            break;
    }
}

//从左边出场
async function translateLeftOut(options) {
    const target = options.targets;
    const duration = options.duration;
    $("#" + target).css({
        transform: `translateX(-${$("#" + target).width()}px)`,
        transition: duration / 1000 + "s",
        "transition-timing-function": !options.ease ? "ease" : options.ease,
    });
    setTimeout(() => {
        $("#" + target).addClass("none");
    }, duration)

}

//从右边入场
function translateRightIn(options) {
    const target = options.targets;
    const duration = options.duration;
    $("#" + target).css({
        left: `${$("#" + target).width()}px`,
    });
    $("#" + target).css({
        transform: `translateX(-${$("#" + target).width()}px)`,
        transition: duration / 1000 + "s",
        "transition-timing-function": !options.ease ? "ease" : options.ease,
    });

}